<template>
  <div class="signup-page">
    <el-form
      ref="joinForm"
      class="auth-form"
      :model="joinForm"
      :rules="rules"
      :disabled="loading"
    >
      <h2 class="form-header">
        Join
      </h2>
      <el-row :gutter="32">
        <el-col :sm="12">
          <el-form-item
            label="First name"
            prop="first_name"
            class="is-no-asterisk"
          >
            <el-input
              v-model="joinForm.first_name"
              placeholder="Enter first name"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item
            label="Surname"
            prop="last_name"
            class="is-no-asterisk"
          >
            <el-input
              v-model="joinForm.last_name"
              placeholder="Enter surname"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="32">
        <el-col :sm="12">
          <el-form-item
            label="Enter your company code"
            prop="code"
            class="is-no-asterisk"
          >
            <el-input
              v-model="joinForm.code"
              v-loading="isValidatingCode"
              placeholder="Enter company code"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-tooltip
            effect="dark"
            content="Please enter a valid company code first"
            placement="top-end"
            :disabled="companyTeams.length > 0"
          >
            <el-form-item
              label="Select your team"
              prop="team_id"
              class="is-no-asterisk"
            >
              <el-select
                v-model="joinForm.team_id"
                placeholder="Please select"
                :disabled="companyTeams.length == 0"
                @change="fetchChildTeams"
              >
                <el-option
                  v-for="team in companyTeams[0]"
                  :key="team.id"
                  :value="team.id"
                  :label="team.name"
                />
              </el-select>
            </el-form-item>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row :gutter="32">
        <el-col
          :offset="12"
          :sm="12"
        >
          <el-form-item
            v-show="companyChildTeams.length"
            label="Select your business area"
            prop="subteam_id"
          >
            <el-select
              v-model="joinForm.subteam_id"
              placeholder="Select"
            >
              <el-option
                v-for="childTeam in companyChildTeams[0]"
                :key="childTeam.id"
                :value="childTeam.id"
                :label="childTeam.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="32">
        <el-col :sm="12">
          <el-form-item
            label="When was the last time you were active?"
            prop="question_option_id"
            class="is-no-asterisk"
          >
            <el-select
              v-model="joinForm.question_option_id"
              placeholder="Please select"
              :disabled="companyQuestions.length == 0"
            >
              <el-option
                v-for="question in companyQuestions[0]"
                :key="question.id"
                :value="question.id"
                :label="question.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="32">
        <el-col :sm="12">
          <el-form-item
            label="Personal email address"
            prop="email"
          >
            <el-input
              v-model="joinForm.email"
              type="email"
              placeholder="Enter email address"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item
            label="Password (min 6 characters)"
            prop="password"
          >
            <el-input
              v-model="joinForm.password"
              type="password"
              auto-complete="off"
              placeholder="Create a password"
              show-password
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="terms-wrapper">
        <el-form-item prop="marketing">
          <el-checkbox v-model="joinForm.marketing">
            <p>
              {{ brand.lang.signUpMarketingText }}
            </p>
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="terms">
          <el-checkbox v-model="joinForm.terms">
            <p>
              I have read and understood the <a
                target="_blank"
                :href="brand.termsLink"
              > Terms of Service </a> and the <a
                target="_blank"
                :href="brand.privacyLink"
              > Privacy Policy</a>.
            </p>
          </el-checkbox>
        </el-form-item>
      </div>
      <button
        id="submit"
        type="submit"
        class="fgh-button lg fgh-button--primary btn-signup"
        :disabled="loading"
        @click.prevent="onSubmit"
      >
        Get started
      </button>
    </el-form>
  </div>
</template>

<script>
import _ from 'lodash'
import authApi from '@/modules/auth/services/auth.api'
import authRules from '@/modules/auth/services/auth.rules'

export default {
  data() { 
    const validateSubTeam = (rule, value, callback) => {
      const team = this.companyTeams[0].find(team => team.id == this.joinForm.team_id)
      if (!team || team.child_teams_count == 0) {
        callback()
      } else if (this.joinForm.subteam_id == '') {
        callback(new Error('Please input your business area'));
      } else {
        callback()
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('The company code field is required.'));
      } else {
        this.validateCode(value, (response) => {
          if (response && response.data && response.data.valid) {
            this.fetchTeams(value)
            callback()
          } else {
            this.isValidatingCode = false
            this.resetTeams()
            callback(new Error('Invalid company code provided, try again'));
          }
        })
      }
    }

    const validateNewPassword =  (rule, value, callback) => {
      if (value === '') {
        callback(new Error('The password field is required.'));
      } else if (value.length < 6) {
        callback(new Error('The password field must not be less than 6 characters'));
      } else {
        callback()
      }
    } 

    return {
      joinForm: {
        first_name: '',
        last_name: '',
        code: '',
        question_option_id: '',
        team_id: '',
        subteam_id: '',
        email: '',
        password: '',
        terms: false,
        marketing: false,
      },
      companyQuestions: [],
      companyChildTeams: [],
      companyTeams: [],
      isValidatingCode: false,
      rules: {
        first_name: [
          { message: 'The first name field is required.', required: true },
        ],
        last_name: [
          { message: 'The surname field is required.', required: true }
        ],
        question_option_id: [
          { message: 'The last active field is required.', required: true }
        ],
        team_id: [
          { message: 'The team field is required.', required: true }
        ],
        subteam_id: [
          { validator: validateSubTeam, message: 'Please select your business area' }
        ],
        code: [
          { validator: validateCode, required: true }
        ],
        email: [
          { validator: authRules.validateEmailAddress, required: true }
        ],
        password: [
          { validator: validateNewPassword }
        ],
        terms: [
          { validator: authRules.validateTerms }
        ],
      },
      loading: false
    };
  },

  created() {
    this.fetchQuestions()
  },

  methods: {
    validateCode: _.debounce(function (code, cb) {
      this.isValidatingCode = true
      authApi
        .validateCompanyCode(code)
        .then((response) => {
          cb(response)
        }).catch((response) => {
          cb(response)
        })
    }, 500),
    fetchQuestions() {
      authApi
        .companyQuestions()
        .then(response => {
          this.companyQuestions.push(
            response.data.data[0].questionOptions.data
          )
        })
    },
    fetchTeams(code) {
      authApi
        .companyTeams({
          company_code: code
        })
        .then(response => {
          this.isValidatingCode = false
          this.companyTeams.push(
            response.data.data
          )
        })
    },
    fetchChildTeams() {
      const team = this.companyTeams[0].find(team => team.id == this.joinForm.team_id)
      if (!team || team.child_teams_count == 0) {
        this.companyChildTeams = []
        this.joinForm.subteam_id = ''
        return
      }
      authApi
        .companyTeams({
          company_code: this.joinForm.code,
          parent_team_id: this.joinForm.team_id
        })
        .then(response => {
          this.companyChildTeams.push(
            response.data.data
          )
        })
    },
    resetTeams() {
      this.companyTeams = []
      this.companyChildTeams = []
      this.joinForm.team_id = ''
      this.joinForm.subteam_id = ''
    },
    onSubmit() {
      this.$refs.joinForm.validate((valid, object) => {
        if (valid) {
          this.loading = true
          authApi.register({
            first_name: this.joinForm.first_name,
            last_name: this.joinForm.last_name,
            company_code: this.joinForm.code,
            question_option_id: this.joinForm.question_option_id,
            team_id: this.joinForm.subteam_id != '' ? this.joinForm.subteam_id :this.joinForm.team_id,
            email: this.joinForm.email,
            password: this.joinForm.password,
            terms: this.joinForm.terms,
            marketing: this.joinForm.marketing,
          }).then(response => {
            this.loading = false
            this.$store.commit('LOGIN_UNVERIFIED_USER', response.data)
            this.$router.push({ name: 'auth-verify-email', params: { id: response.data.id, email: response.data.email } })
          }).catch(error => {
            this.loading = false
            if (error.response && error.response.status == 422 && error.response.data) {
              _.each(error.response.data.errors, (obj, index) => {
                this.$notify.error({
                  title: 'Error',
                  message: obj[0]
                })
              });
            } else {
              this.$notify.error({
                title: 'Error',
                message: 'An unexpected error occured. Please try again later',
              })
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
.signup-page {
  .auth-form {
    max-width: 800px;

    @media (max-width: 840px) {
      padding: 40px;
    }

    .terms-wrapper {
      .el-form-item {
        margin-bottom: 0;
        &.is-error {
          margin-bottom: 22px;
        }
      }

      .el-checkbox {
        display:flex;
        align-items: center;
        white-space: normal;
        text-align: left;
        .el-checkbox__label {
          font-size: 12px;
        }
      }
    }
    .btn-signup {
      margin-top: 22px;
    }
  }
}
</style>
